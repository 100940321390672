<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div
            class="box-overlay"
            :class="{'open': isSidebar}"
            @click="isSidebar = !isSidebar"
          />
          <div
            class="box-sidebar pb-3 shadow-sm"
            :class="{'open': isSidebar}"
          >
            <sidebar />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn
                icon
                @click="isSidebar = !isSidebar"
              >
                <v-icon dark>
                  mdi-format-list-bulleted-square
                </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex justify-space-between flex-wrap mb-5">
                <div class="d-flex align-center ">
                  <v-avatar
                    tile
                    size="25"
                    class="me-3"
                  >
                    <img
                      src="@/assets/images/icons/user_filled.svg"
                      alt=""
                    >
                  </v-avatar>
                  <h2 class="mb-0">
                    Thông tin cá nhân
                  </h2>
                </div>
                <v-btn
                  outlined
                  color="primary"
                  class="text-capitalize font-600"
                  @click="$router.push('ca-nhan/sua-thong-tin')"
                >
                  Sửa thông tin
                </v-btn>
              </div>

              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  lg="6"
                >
                  <base-card>
                    <div class="pa-5">
                      <div class="d-flex justify-space-between align-center flex-wrap">
                        <div class="d-flex align-center">
                          <!-- <v-avatar
                            size="64"
                            class="me-4"
                          >
                            <img
                              src="@/assets/images/faces/ralph.png"
                              alt=""
                            >
                          </v-avatar> -->
                          <div>
                            <h4 class="font-600">
                              {{ UserData.ten }}
                            </h4>
                            <p class="mb-0 grey--text text--darken-1">
                              Số dư: <span class="primary--text">{{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(UserData.sodu) }}</span>
                            </p>
                            <p class="mb-0 grey--text text--darken-1">
                              Cọc: <span class="primary--text">{{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(UserData.dongbang) }}</span>
                            </p>
                          </div>
                        </div>
                        <p class="mb-0 grey--text text--darken-1">
                          {{ nhomTK }}
                        </p>
                      </div>
                    </div>
                  </base-card>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="3"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      16
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      Đơn hàng
                    </p>
                  </base-card>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="3"
                >
                  <base-card class="h-100 pa-4 d-flex flex-column text-center align-center justify-center">
                    <h3 class="font-600 primary--text mb-0">
                      02
                    </h3>
                    <p class="mb-0 text-12 grey--text text--darken-1">
                      Đấu giá
                    </p>
                  </base-card>
                </v-col>
                <v-col cols="12">
                  <base-card>
                    <div class="pa-4 d-flex justify-space-between flex-wrap">
                      <div class="mx-2 my-2">
                        <p class="text-sm grey--text text--darken-1 mb-1">
                          Tên
                        </p>
                        <span>{{ UserData.ten }}</span>
                      </div>
                      <div class="mx-2 my-2">
                        <p class="text-sm grey--text text--darken-1 mb-1">
                          Email
                        </p>
                        <span>{{ UserData.email }}</span>
                      </div>
                      <div class="mx-2 my-2">
                        <p class="text-sm grey--text text--darken-1 mb-1">
                          Số điện thoại
                        </p>
                        <span>{{ UserData.sodienthoai }}</span>
                      </div>
                    </div>
                  </base-card>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import sidebar from '@/components/DashboardSidebar'
  import { mapGetters } from 'vuex'
  export default {
    components: {
      sidebar,

    },
    data () {
      return {
        isSidebar: false,

      }
    },
    computed: {
      ...mapGetters(['UserData']),
      // eslint-disable-next-line vue/return-in-computed-property
      nhomTK () {
        if (this.UserData.cap === 'binh_thuong') {
          return 'Tài khoản thường'
        } else if (this.UserData.cap === 'VIP') {
          return 'Tài khoản V.I.P'
        }
      },
    },
  }

</script>
